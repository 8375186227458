import React, { useRef } from "react"
import Layout from "../components/layout"
import HomepageItem from "../components/HomepageItem";
import SEO from "../components/seo"

import "../assets/less/homepage.less"

export default ({ data }) => {
  const activeProject = useRef(null)

  const metaDataTitle = data.wordpressPage.acf.metadata_title ? data.wordpressPage.acf.metadata_title : "Axel Olson"
  const metaDataDescription = data.wordpressPage.acf.metadata_description ? data.wordpressPage.acf.metadata_description : "Architect."
  const metaDataImg = data.wordpressPage.acf.metadata_image ? data.wordpressPage.acf.metadata_image.localFile.url : ''

  return (
    <Layout page="What">
      <SEO title={metaDataTitle}
           description={metaDataDescription}
           imageURL={metaDataImg} />
      <div className="projects">
        {data.allWordpressWpProjects.edges.map(({ node }, index) => (
          <div className="project"
               key={index}
               ref={activeProject}>
            <HomepageItem data={node} index={index}/>
          </div>
        ))}
      </div>
      <div className="mobile-hack">
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
  wordpressPage(title: { eq: "Homepage" }) {
    acf {
      metadata_title
      metadata_description
      metadata_image {
        localFile {
          url
        }
      }
    }
  }
  allWordpressWpProjects(sort: {fields: [date]}) {
    edges {
      node {
        title
        id
        acf {
          location_name
          description
          project_page_additional_details
          location_latitude
          location_longitude
          project_page_top_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 700) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          homepage_position_x
          homepage_position_y
          project_image {
            source_url
            localFile {
              childImageSharp {
                fixed(webpQuality: 10, width: 500) {
                  ...GatsbyImageSharpFixed
                }
                fluid(maxWidth: 350) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          project_image_hover {
            source_url
            localFile {
              childImageSharp {
                fixed(webpQuality: 10, width: 500) {
                  ...GatsbyImageSharpFixed
                }
                fluid(maxWidth: 350) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        content
        slug
      }
    }
  }
}

`
