import React, { useState, useRef } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import decodeHtmlEntity from "../helpers/decodeHtmlEntity"

export default (props) => {
  let node = props.data
  const clickedOnEl = useRef(null)

  const [isHovered, toggleHover] = useState(false)

  let zIndex = 1
  if(node.title === "Penumbra") {
    zIndex = 2
  } else if(node.title === `About Face`) {
    zIndex = 0
  }

  const desktopItem = (
    <Link className="image-wrapper"
          to={`/projects/` + props.data.slug}
          style={{left: parseInt(node.acf.homepage_position_x),
                  top: parseInt(node.acf.homepage_position_y),
                  zIndex: zIndex}}
          onMouseEnter={() => { toggleHover(true)} }
          onMouseLeave={() => { toggleHover(false)} }
      >
      <Img className={"project-image " + node.id}
           fixed={node.acf.project_image.localFile.childImageSharp.fixed}
           height={100}
           width={100}
           style={{position: "relative"}}
           alt={node.title} />
      <Img className={"project-image-hover " + node.id}
           fixed={node.acf.project_image_hover.localFile.childImageSharp.fixed}
           style={{position: "absolute"}}
           alt={node.title}
           ref={clickedOnEl}/>
      <div className={`hover-info ${isHovered ? 'hovered' : 'not-hovered'}` }>
        <div className="hover-info-title">
          {decodeHtmlEntity(node.title)}
        </div>
        <div className="hover-info-description">
          {node.acf.description}
        </div>
      </div>
    </Link>
  )
  const mobileItem = (
    <Link className="image-wrapper"
          to={`/projects/` + props.data.slug}
          style={{left: parseInt(node.acf.homepage_position_x) / 1.4,
                  top: parseInt(node.acf.homepage_position_y) / 1.4 }}
          onMouseEnter={() => { toggleHover(true)} }
          onMouseLeave={() => { toggleHover(false)} }
      >
      <Img className={"project-image " + node.id}
           fixed={node.acf.project_image.localFile.childImageSharp.fixed}
           height={100}
           width={100}
           style={{position: "relative"}}
           alt={node.title} />
      <Img className={"project-image-hover " + node.id}
           fixed={node.acf.project_image_hover.localFile.childImageSharp.fixed}
           style={{position: "absolute"}}
           alt={node.title}
           ref={clickedOnEl}/>
      <div className={`hover-info ${isHovered ? 'hovered' : 'not-hovered'}` }>
        <div className="hover-info-title">
          {decodeHtmlEntity(node.title)}
        </div>
        <div className="hover-info-description">
          {node.acf.description}
        </div>
      </div>
    </Link>
  )

  // if(window) {
  //   if(window.innerWidth > 768) {
  //     return desktopItem
  //   } else {
  //     return mobileItem
  //   }
  //   console.log(window)
  //   return desktopItem
  // } else {
  //   return desktopItem
  // }
  return desktopItem
}
